.graph-autonomie {

    @apply flex flex-col  md:gap-y-2  font-bold   ;
    &__pourcentage {
        @apply flex flex-col gap-1 text-4xl md:text-4xl m-auto text-center text-[#0488F9] md:mt-2 font-extrabold;
    }
    &__detail-pourcentage{
        @apply flex flex-row items-baseline justify-between my-1  font-bold;
        &__pourcentage-min{
            @apply text-red-500 flex-none text-2xl md:text-4xl;
        }
        &__pourcentage-slider{
            @apply rounded-full flex-1 mx-2 md:mx-5 h-3 md:h-4;
        }
        &__pourcentage-max{
            @apply text-emerald-400 flex-none text-2xl md:text-4xl;
        }
    }
  
    &__titre-taux-equipement-equivalent{
        @apply text-blue-700 text-sm md:text-xl m-auto text-center;
    }
    &__facture{
        @apply flex flex-wrap  gap-2 justify-center mx-auto md:justify-center text-white font-bold py-4;
        &-sans-installation{
            @apply bg-blue-500  rounded-lg flex flex-col items-center justify-around mx-2 gap-1 p-2;
            &__title{
                @apply break-normal text-sm md:text-xl text-center;
            }
            &__value{
                @apply  text-xl md:text-2xl text-center;
            }
            &__footer{
                @apply text-xl md:text-2xl text-center;
            }
        }
        &-avec-installation{
            @apply bg-blue-900  rounded-lg flex flex-col items-center justify-around mx-2 gap-1 p-2;
            &__title{
                @apply  break-normal text-sm md:text-xl text-center;
            }
            &__value{
                @apply   text-xl md:text-2xl text-center;
            }
            &__footer{
                @apply text-xl text-xl md:text-2xl text-center;
            }
        }
       
    }
    &__bilan{
        @apply flex flex-col items-center gap-2 my-2;
        &__date{
            @apply text-center text-[#0488F9] md:mt-2 text-xl md:text-3xl font-extrabold;
        }
        &__description {
            @apply my-1 md:my-1 text-blue-700 text-sm  md:text-xl m-auto text-center;
        }
        &__value{
            @apply text-4xl md:text-4xl m-auto text-center text-[#0488F9] md:mt-2 font-extrabold;
        }
    }
    &__footer{
        @apply text-center text-sm/[14px] m-auto;
    }


} 