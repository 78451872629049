.main-house {
  @apply relative h-full w-full md:max-w-[550px] flex flex-wrap  rounded-[20px] mx-auto px-2 py-5;

  &__container {
    @apply container w-[530px] relative h-[480px]  mx-auto;

    &__maison-ifpen {
      @apply absolute w-[530px] top-0 left-0;
    }
  
    &__compteur-ifpen {
      @apply w-[75px] left-[75%] top-[65%] absolute;
    }

    &__external-consumption-flow-label {
      @apply absolute left-[83%] top-[75%] bg-[#E00012] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 3px #8a010dbd;
    }

    &__external-consumption-flow {
      @apply left-[84%] top-[67%] absolute;
    }

    &__supplier-flow {
      @apply left-[84%] top-[67%] absolute;
    }

    &__supplier-flow-label {
      @apply absolute left-[83%] top-[75%] bg-[#E02FEF] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 3px #8f1f99d8;
    }

    &__supplier-flow-label-none {
      @apply absolute left-[83%] top-[75%] bg-[#EAEAEA] rounded-full text-center px-3 py-1 text-black font-bold;
      text-shadow: 0px 0px 3px #0000005c;
    }

    &__solar-flow {
      @apply absolute  left-[48%] top-[20%] max-w-xs;
    }

    &__solar-flow-label {
      @apply absolute left-[70%] top-[20%]  bg-[#ebdc0e] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 4px #9c930abd;
    }

    &__consumption-flow {
      @apply left-[33%] top-[30%] absolute;
    }

    &__consumption-flow-label {
      @apply absolute left-[30%] top-[60%] bg-[#DADADA] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 3px #1f1f1fcc;
    }
    &__batterie-ifpen {
      @apply relative max-w-[30px] left-[44%] top-[35%] max-h-10;
    }
    &__battery-charge-input-flow {
      @apply absolute left-[45%] top-[35%]  max-w-xs;
    }

    &__battery-charge-output-flow {
      @apply absolute left-[45%] top-[35%]  max-w-xs;
    }

    &__battery-input-flow-label {
      @apply absolute left-[57%] top-[60%] bg-[#43D820] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 3px #2e9717b9;
    }

    &__battery-input-flow-label-none {
      @apply absolute left-[57%] top-[60%] bg-[#EAEAEA] rounded-full text-center px-3 py-1 text-black font-bold;
      text-shadow: 0px 0px 3px #0000005c;
    }

    &__battery-output-flow-label {
      @apply absolute left-[57%] top-[60%] bg-[#10AD77] rounded-full text-center px-3 py-1 text-white font-bold;
      text-shadow: 0px 0px 3px #0a6b49cb;
    }
  }
  &__top {
    @apply w-full flex flex-row items-center mx-auto;
  }

  &__widgets {
    @apply w-full flex justify-between my-2 gap-2 overflow-scroll max-w-full px-2;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .main-house__widgets::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .main-house__widgets {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
